
import Vue from 'vue';
import { SESSION_KEY } from '@/utils/constants';

export default Vue.extend({
  name: 'Logout',
  mounted() {
    localStorage.setItem(SESSION_KEY, '');
    window.location.replace('/' + window.location.search);
  },
  render(h) {
    return h('div');
  },
});
